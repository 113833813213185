import produce from 'immer';
import * as types from './types';

export const initialState = {
  data: null,
  job: {},
  jobijoba_offers: {},
};

const likesReducer = (state = initialState, action) => {
  return produce(state, () => {
    
    switch (action.type) {
      case types.GET_USER_LIKES_SUCCESS:
        return {
          ...state,
          data: action.payload.likes,
        };
        break;

      case types.LIKE_ELEMENT_SUCCESS:
        return {
          ...state,
          [action.like.type]: {
            ...state[action.like.type],
            [action.like.id]: action.payload.like,
          },
        };

        case types.GET_LIKE_STATUS_SUCCESS: {
            return {
                ...state,
                [action.like.type]: {
                    ...state[action.like.type],
                    [action.like.id]: action.payload.like,
                },
            };
        }
        break;
    }
  });
}
export default likesReducer;