import produce from 'immer';
import * as types from './types';

export function openSidebar(name, toggle = false, metadata = null) {
    return {
      type: types.OPEN_SIDEBAR,
      payload: {
        name,
        metadata,
        toggle,
      },
    };
}

export function closeSidebar(name) {
    return {
      type: types.CLOSE_SIDEBAR,
      payload: {
        name,
      },
    };
}

export const initialState = {
    main: null,
};

const sidebarReducer = (state = initialState, action) =>
  produce(state, () => {
    switch (action.type) {
      case types.ADD_SIDEBAR: {
        const position = action.payload.params?.position || 'right';

        return {
          ...state,
          [position]: {
            content: action.payload.content,
            params: action.payload.params,
          },
        };
      }

      case types.REMOVE_SIDEBAR: {
        const position = action.payload.params?.position || 'right';

        return {
          ...state,
          [position]: null,
        };
      }

      case types.OPEN_SIDEBAR:
        if (state.main?.metadata?.show === action.payload?.metadata?.show && action.payload.toggle) {
          return {
            ...state,
            main: null,
          };
        }

        return {
          ...state,
          main: {
            name: action.payload.name,
            metadata: action.payload.metadata,
          },
        };
        break;

      case types.CLOSE_SIDEBAR:
        return {
          ...state,
          main: null,
        };
    }
  });

export default sidebarReducer;