import * as types from './types.js';

export function sendEventAnalytics(commonEvent = {}, googleAnalytics = {}, amplitudeAnalytics = {}, globalData = {}) {
    const analytics = {};

    if (commonEvent) {
        analytics['commonEvent'] = commonEvent || {};
    }

    if (googleAnalytics) {
        analytics['google'] = googleAnalytics || commonEvent || {};
    }

    if (amplitudeAnalytics) {
        analytics['amplitude'] = amplitudeAnalytics || commonEvent || {};
    }

    for (const analytic of Object.keys(analytics)) {
        analytics[analytic]['data'] = {
            ...analytics[analytic]?.['data'] || {},
            ...globalData
        };
    }

    return {
        type: types.SEND_EVENT_ANALYTICS,
        analytics
    };
}