import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Card, Typo, Divider, TextInput, Button, FlatMenu, MenuItem, MenuDivider } from '@ozlydev/ozly-sand-ui';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { closeSidebar, removeSidebar } from '../../../redux/sidebar/actions';
import { openDialog } from '../../../redux/dialogs/actions';
import { updateSystemPrompt, resetChatHistory } from '../../../redux/chat/actions';
import JobPage from '../../../containers/JobPage';
import RagPage from '../../../containers/RagPage';
import HeaderLink from '../../Header/HeaderLink';
import { useLocation } from 'react-router-dom';
import ThemeCard from './Themes/ThemeCard'
import Img1 from './Themes/img/1.png';
import Img2 from './Themes/img/2.png';
import Img3 from './Themes/img/3.png';

const width = {
  'job': '50vw',
  'right': '50vw',
  'menu': '280px',
  'themes': '360px',
  ...['metier', 'formation', 'structure', 'offer'].reduce((acc, key) => {
    acc[key] = '100%';
    return acc;
  }, {})
}

const mobileWidth = {
  'job': '100%',
  'right': '100%',
  'menu': '100vw',
  'themes': '100vw',
  ...['metier', 'formation', 'structure', 'offer'].reduce((acc, key) => {
    acc[key] = '100vw';
    return acc;
  }, {})
}

const A = styled.a`
  &.active {
    font-weight: bold;
    
    > li {
      background-color: #D79F8110 !important;
    }
  }
  color: initial;
  text-decoration: none;
`;

const Wrapper = styled.div`
  .szh-menu { // user-agent
    padding-inline-start: 0 !important;
  }

  opacity: 0;
  transition: 1s ease;

  border: 0px;
  background-color: none;
  z-index: 18;
  position: fixed;
  top: 0;
  width: 100%;
  pointer-events: none;
  overflow: hidden;
  width: 0;
  right: 80px;

  &[data-visible='true'] {
      width: ${props => width[props.$type]};
      pointer-events: initial;
      opacity: 1;

      @media (max-width: 768px) {
        width: ${props => mobileWidth[props.$type] || '100%'};
      }

  }

  height: 100%;

  ${(props) =>
    props.$isjob
      ? css`
      max-width: 50vw;
      width: 100%;
      border: none;
      bakcground-color: transparent;

      @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
      }
    ` : css`
      max-width: auto;
    `}

    @media (max-width: 768px) {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: #fff3e8;
      z-index: 10;
      height: 100%;
      border: none;
    }

    ${(props) =>
    !props.$ischat
      ? css`
       position: fixed;
       right: 96px;
       top: 24px;
       height: calc(100% - 48px);
      `
      : css``}
`;

const SidebarContent = styled.div`
    height: 100%;
    position: relative;

    width: ${props => {
      return width[props.$type];
    }};
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: rgba(215, 159, 129, 0.2) transparent;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    &:hover {
        &::-webkit-scrollbar {
            width: 6px;
            background-color: transparent;
        }
        &::-webkit-scrollbar:hover {
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb:vertical {
            background: rgba(215, 159, 129, 0.2);
        }
        &::-webkit-scrollbar-thumb:vertical:active {
            background: rgba(215, 159, 129, 0.4);
        }
    }

    @media (max-width: 768px) {
      width: ${props => mobileWidth[props.$type] || '100%'};
    }
  `;

const FlatMenuThemes = styled.div`
  .szh-menu-container > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const AuthCtas = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 32px;

    @media (max-width: 768px) {
      bottom: 64px;
    }
`;

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        const menuContainer = document.getElementById('chatMenuContainer');
        const DialogContainer = document.getElementById('DialogContainer');

        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        if ((menuContainer?.contains(event.target)) || (DialogContainer?.contains(event.target))) {
          return;
        }

        if ([4, 8].includes(menuContainer.compareDocumentPosition(event.target))) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [ref, handler],
  );
}

const ChatSidebar = () => {
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const sidebarRef = useRef(null);
  const mainSidebar = useSelector((state) => state.sidebar.main);
  const rightSidebar = useSelector((state) => state.sidebar.right);


  const user = useSelector((state) => state.global?.user);
  const systemPrompt = useSelector((state) => state.chat.system_prompt);
  const searchParams = { id: mainSidebar?.metadata?.job?._id, searchId: mainSidebar?.metadata?.search?._id };
  const isJob = (mainSidebar?.name === 'main' && ['job', 'metier', 'formation', 'structure', 'offer'].includes(mainSidebar?.metadata?.show));

  useOnClickOutside(sidebarRef, useCallback(() => {
    if (isVisible) {
      dispatch(closeSidebar('main'));
      dispatch(removeSidebar('right'));
    }
  }, [isVisible]));

  useEffect(() => {
    if (mainSidebar?.name === 'main' || rightSidebar != null) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [mainSidebar, rightSidebar]);

  const openLoginDialog = () => {
    dispatch(openDialog('login'));
  };

  const openRegisterDialog = () => {
    dispatch(openDialog('register'));
  };

  return (
    <Wrapper
      $isVisible={isVisible}
      data-visible={isVisible}
      ref={sidebarRef}
      $ischat={['/', 'dev'].includes(location.pathname)}
      $isjob={isJob}
      $type={mainSidebar?.metadata?.show || (rightSidebar != null && 'right')}
    >
      <SidebarContent
        $isjob={isJob}
        $type={mainSidebar?.metadata?.show}
      >
        {mainSidebar?.name === 'main' && mainSidebar?.metadata?.show === 'menu' &&
          <FlatMenu nested title="Menu">
            <HeaderLink to="/" exact>
              <MenuItem>Voir les agents</MenuItem>
            </HeaderLink>
            <HeaderLink to="/alerts">
              <MenuItem>Alertes</MenuItem>
              </HeaderLink>
            <HeaderLink to="/likes">
              <MenuItem>Likes</MenuItem>
            </HeaderLink>
            <MenuDivider />
            {/* <A href="https://8o5c1vpksuu.typeform.com/to/JZhVkvFy" target="_blank">
              <MenuItem>Donner son avis</MenuItem>
            </A> */}
            <MenuItem onClick={() => {
              dispatch(openDialog('platformShare', { title: 'Partager Ozly à un ami' }));
            }}>Partager Ozly à un ami</MenuItem>
            <MenuDivider />
            <A href="https://hello.ozly.ai/about" target='_blank'>
              <MenuItem>À propos d’Ozly</MenuItem>
            </A>
            <MenuDivider />
            <A href="https://www.instagram.com/ozly_ai/" target="_blank">
              <MenuItem>Suis-nous sur Instagram</MenuItem>
            </A>
            {!user?.email && <>
              <AuthCtas>
                <Button variant="primary" gutter="medium" onClick={openLoginDialog}>Se connecter</Button>
                <Button variant="conversion" active onClick={openRegisterDialog}>S'inscrire</Button>
              </AuthCtas>
            </>}
            {user?.email &&
              <>
                <HeaderLink to="/settings">
                  <MenuItem>Paramètres</MenuItem>
                </HeaderLink>
                <HeaderLink to="/logout">
                  <MenuItem>Se déconnecter</MenuItem>
                </HeaderLink>
              </>
            }
          </FlatMenu>}

        {mainSidebar?.name === 'main' && mainSidebar?.metadata?.show === 'themes' &&
          <FlatMenuThemes>
            <FlatMenu nested title="Échangez">
              <ThemeCard
                title="Découvre des métiers"
                subtitle="Dis à Ozly ce qui te plait et elle te proposera des métiers qui pourraient t'intéresser"
                img={Img1} />
              <ThemeCard
                title="Identifie la bonne formation"
                subtitle="Dis à Ozly le métier que tu veux faire ou le secteur dans lequel tu veux travailler et elle te proposera des formations adaptées"
                img={Img2} />
              <ThemeCard
                title="Trouve ton lieu de formation"
                subtitle="Lycée, centre de formation, universités, écoles... Demande à Ozly de t'aider à trouver ton prochain lieu de formation"
                img={Img3} />
            </FlatMenu>
          </FlatMenuThemes>
        }

        {mainSidebar?.name === 'main' && mainSidebar?.metadata?.show === 'job' &&
          <JobPage match={searchParams} />
        }

        {rightSidebar != null && (rightSidebar.content)}

        {mainSidebar?.name === 'main' && ['metier', 'formation', 'structure', 'offer'].includes(mainSidebar?.metadata?.show) &&
          <RagPage match={{
              id: mainSidebar.metadata[mainSidebar.metadata.show]?._id,
              type: mainSidebar.metadata.show,
              coordinates: mainSidebar.metadata[mainSidebar.metadata.show]?.coordinates,
              distance: mainSidebar.metadata[mainSidebar.metadata.show]?.distance
          }} />
        }
      </SidebarContent>
    </Wrapper>
  );
};

export default ChatSidebar;

/* {mainSidebar?.name === 'main' && mainSidebar?.metadata?.show === 'themes' &&
            // <ThemesContainer>
                <div>
           
                <textarea
                    value={systemPrompt}
                    onChange={(e) => {
                        dispatch(updateSystemPrompt(e.target.value));
                    }}
                    placeholder="SYSTEM_PROMPT"
                    rows={40}
                />

                <Button variant="primary" onClick={() => {
                    dispatch(resetChatHistory());
                }
                }>Reset history</Button>
          </div>
        } */