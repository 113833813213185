export const INVOKE_CHAIN = 'INVOKE_CHAIN';
export const INVOKE_CHAIN_SUCCESS = 'INVOKE_CHAIN_SUCCESS';
export const INVOKE_CHAIN_FAIL = 'INVOKE_CHAIN_FAIL';

export const GET_CHAT_HISTORY = 'GET_CHAT_HISTORY';
export const GET_CHAT_HISTORY_SUCCESS = 'GET_CHAT_HISTORY_SUCCESS';
export const GET_CHAT_HISTORY_FAIL = 'GET_CHAT_HISTORY_FAIL';

export const UPDATE_AGENT_STATUS = 'UPDATE_AGENT_STATUS';

export const RESET_CHAT_HISTORY = 'RESET_CHAT_HISTORY';
export const RESET_CHAT_HISTORY_DONE = 'RESET_CHAT_HISTORY_DONE';

export const UPDATE_STATUS_CHAT_TOOL = 'UPDATE_STATUS_CHAT_TOOL';
export const UPDATE_SYSTEM_PROMPT = 'UPDATE_SYSTEM_PROMPT';

// TMP

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const STREAM_MESSAGE = 'STREAM_MESSAGE';
