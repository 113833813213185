import React, { memo } from 'react';
import { Chip, ChipContainer } from '@ozlydev/ozly-sand-ui';

const JobBadges = ({ job, justifyContent, size }) => {
    let contractTypes = Array.isArray(job?.contractType) ? job?.contractType : [job?.contractType];
    contractTypes = contractTypes.map(contractType => contractType.toLowerCase());

    return (
      <ChipContainer justifyContent={justifyContent} size={size}>
        <Chip leftIcon="Geoloc" mr="8" gutter="small" size={size}>{job?.city}</Chip>
        {contractTypes.includes('CDI') && <Chip leftIcon="Briefcase" gutter="small" size={size}>CDI</Chip>}
        {contractTypes.includes('CDD') && <Chip leftIcon="Briefcase" gutter="small" size={size}>CDD</Chip>}
        {contractTypes.includes('INTERN') && <Chip leftIcon="Briefcase" gutter="small" size={size}>Stage</Chip>}
        {contractTypes.includes('LIB') && <Chip leftIcon="Briefcase" gutter="small" size={size}>Freelance</Chip>}
        {['apprentice', 'alternance'].find(val => contractTypes.includes(val)) != null && <Chip leftIcon="Briefcase" gutter="small" size={size}>Alternance</Chip>}
        {contractTypes.includes('APPRENTICE_PRO') && <Chip leftIcon="Briefcase" gutter="small" size={size}>Contrat Pro</Chip>}
        {contractTypes.includes('CTT') && <Chip leftIcon="Briefcase" gutter="small" size={size}>Interim</Chip>}
        {contractTypes.includes('VIE') && <Chip leftIcon="Briefcase" gutter="small" size={size}>VIE</Chip>}
        {contractTypes.includes('CUI') && <Chip leftIcon="Briefcase" gutter="small" size={size}>CUI</Chip>}
        {contractTypes.includes('CAE') && <Chip leftIcon="Briefcase" gutter="small" size={size}>CAE</Chip>}
        {contractTypes.includes('CIE') && <Chip leftIcon="Briefcase" gutter="small" size={size}>CIE</Chip>}
      </ChipContainer>
    );
};

JobBadges.defaultProps = {
    size: 'medium',
    justifyContent: 'flex-start',
};

export default memo(JobBadges);