import * as types from './types';
import { getChatHistory } from "../chat/actions";
import { getAgentFromUrl } from '../../utils/urlSeo';

const agentMiddleware = (store) => (next) => (action) => {

  const { type } = action;

  if (type === types.GET_AGENTS_CHARACTERS_SUCCESS) {
    let agent = undefined;
    for (let group of action.payload.characters) {
        for (let character of group.Characters) {
          if (character.pathNames?.includes(getAgentFromUrl())) {
            agent = character;
            break;
          }
        }
    }

    if (agent) {
      store.dispatch(getChatHistory());
    }
  }

  return next(action);
};

export default agentMiddleware;


