import { getAgentFromUrl } from '../../utils/urlSeo';
import * as types from './types';

export function invokeChain(chainName, payload, toChat = false) {
  return {
    type: [types.INVOKE_CHAIN, types.INVOKE_CHAIN_SUCCESS, types.INVOKE_CHAIN_FAIL],
    body: payload,
    baseUrl: `/${chainName}/invoke`,
    requestType: 'post',
    loader: 'invokeChain',
    langServe: true,
    chain: {
      name: chainName,
      toChat
    },
    takeEvery: true,
  };
}

export function streamMessage(data) {
  return {
    type: types.STREAM_MESSAGE,
    payload: data,
  };
}

export function updateAgentStatus(status) {
  return {
    type: types.UPDATE_AGENT_STATUS,
    payload: status,
  };
}

export function addMessage(message) {
  return {
    type: types.ADD_MESSAGE,
    payload: message,
  };
}

export function getChatHistory() {
  const originFrom = getAgentFromUrl();

  return {
    type: [types.GET_CHAT_HISTORY, types.GET_CHAT_HISTORY_SUCCESS, types.GET_CHAT_HISTORY_FAIL],
    baseUrl: `/api/chat/history?origin=${originFrom}`,
    requestType: 'get',
    loader: 'getChatHistory',
  };
}

export function resetChatHistory() {
  return {
    type: types.RESET_CHAT_HISTORY,
  };
}

export function updateSystemPrompt(prompt) {
  return {
    type: types.UPDATE_SYSTEM_PROMPT,
    payload: prompt,
  };
}
