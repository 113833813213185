import * as types from './types';
import * as globalTypes from '../../containers/App/constants';
import { OPEN_SIDEBAR } from '../sidebar/types';

const initialState = {
    data: {
    },
    similar: {
    },
    currentSearch: {
        index: 0,
        isLastPage: false,
    },
    search: {

    },
    groupIndexes: {
        search: {},
    }
  };
  
  const ragElementsReducer = (state = initialState, action) => {
    switch (action.type) {
      case OPEN_SIDEBAR: {
        return state;
        // if (action.payload?.metadata?.show !== 'ragElement') {
        //     return state;
        // }

        // const ragElementId = action.payload.metadata.ragElement._id;
        // const searchId = action.payload.metadata.search._id;
        // const currentResults = state.ragElements?.search?.[searchId]?.results || [];
        // const currentIndex = currentResults?.findIndex(ragElement => ragElement._id === ragElementId) || 0;

        // return {
        //     ...state,
        //     groupIndexes: {
        //         ...state.groupIndexes,
        //         search: {
        //             ...state.groupIndexes?.search,
        //             [searchId]: {
        //                 index: currentIndex,
        //                 isLastPage: currentIndex >= currentResults.length - 1,
        //             },
        //         },
        //     } 
        // }
      }


      case types.GET_RAGELEMENTS_BY_SEARCH_ID_SUCCESS: {
        const results = action.payload.results;
        const newRagElements = {};

        results?.forEach((ragElement) => {
            newRagElements[ragElement._id] = ragElement;
        });

        return {
            ...state,
            data: {
                ...state.data,
                ...newRagElements,
            },
            search: {
                ...state.search,
                [action.search.id]: {
                    results,
                },
            },
            groupIndexes: {
                    ...state.search?.groupIndexes,
                    search: {
                        ...state.search?.groupIndexes?.search,
                        [action.search.id]: {
                            index: 0,
                            isLastPage: false,
                        },
                    },
                },
            };
        }
    
      case types.GET_RAGELEMENT_SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.data.baseId]: action.payload.data,
                },
                errors: {
                    ...state.errors,
                    [action.payload.data._id]: null,
                },
            };
        }

        case types.GET_RAGELEMENT_FAIL: {
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.ragElements.ragElementId]: true,
                },
            };
        }

        case types.GET_NEXT_RAGELEMENT_DONE:
        case types.GET_PREVIOUS_RAGELEMENT_DONE: {
            if (action.payload?.groupIndexes) {
                return {
                    ...state,
                    groupIndexes: {
                        ...state.groupIndexes,
                        [action.payload.groupType]: {
                            ...state.groupIndexes?.[action.payload.groupType],
                            [action.payload.groupValue]: {
                                index: action.payload.index,
                                isLastPage: action.payload.isLastPage,
                            },
                        },
                    } 
                };
            }

            return { // single search (need to be removed later)
                ...state,
                currentSearch: action.payload,
            };
        }

        case types.GET_RAGELEMENT_BY_IDS_SUCCESS: {
            const results = action.payload.results;
            const newRagElements = {};

            results?.forEach((ragElement) => {
                newRagElements[ragElement._id] = ragElement;
            });

            return {
                ...state,
                data: {
                    ...state.data,
                    ...newRagElements,
                },
                // similar: {
                //     [action.ragElements.ragElementId]: {
                //         ...state.similar[action.ragElements.ragElementId],
                //         [action.ragElements.similarType]: results,
                //     },
                // },
            };
        }

        case types.SET_RAGELEMENT_DATA: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.data._id]: action.data,
                },
            };
        }
      default:
        return state;
    }
  };
  
  export default ragElementsReducer;
  