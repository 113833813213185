import * as types from './types';

export const addSidebar = (content, params = {}) => ({
    type: types.ADD_SIDEBAR,
    payload: { content, params },
});

export const removeSidebar = (params) => ({
    type: types.REMOVE_SIDEBAR,
    payload: {
        params,
    },
});

export function openSidebar(name, toggle, metadata = null) {
    return {
      type: types.OPEN_SIDEBAR,
      payload: {
        name,
        metadata,
        toggle,
      },
    };
}

export function closeSidebar(name) {
    return {
      type: types.CLOSE_SIDEBAR,
      payload: {
        name,
      },
    };
}
